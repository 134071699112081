import {
    Component,
    EventEmitter,
    Output,
    OnDestroy,
    Input,
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core'
import moment from 'moment'
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms'
import { ToastHelper } from '@app/core/services/toast.service'
import { markControlsAsTouched } from '@app/shared/utils/markControlsAsTouched.util'
import { FOLLOW_UP_RESOURCE } from '@app/shared/models'
import * as fromModalStore from '@app/modals/store'
import * as fromCoreStore from '@app/core/store'
import * as fromShopStore from '@app/shop/store'
import { Store } from '@ngrx/store'

import { OpenModal, CloseModal } from '@app/modals/store/actions/layout.actions'
import { MULTI_SELECT_PRESET_VALUE_CONFIG, } from '@app/shared/buttons/select.config'
import { multiselectGetValues } from '@app/shared'
import { Router } from '@angular/router'
import { AuthService } from '@app/users/services'
import { popupService } from '@app/shared/services/popup.service'
import { ExpenseService } from '@app/expense/services'
import { Subject, takeUntil } from 'rxjs'

@Component({
    selector: 'app-expense-details',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './expense-details.component.html',
    styleUrls: ['./expense-details.component.scss']
})

export class ExpenseDetailsComponent implements OnDestroy, OnInit {

    @Output() onSave = new EventEmitter()
    @Output() onChange = new EventEmitter()
    @Output() onDelete = new EventEmitter()

    @Input()
    currentUser
    @Input()
    users

    @Input()
    taskCount

    @Input()
    set accountsCharts(val) {
        if (val && !this?._accountsCharts?.length) {
            this._accountsCharts = val.map(g => {
                g.accounts.filter(a => a?.name).map((a) => {
                    a._name = a?._contact?.name ? `${a.name}, ${a?._contact?.name}` : a.name
                    return a
                })
                return g
            })
        }
    }
    get accountsCharts() {
        return this._accountsCharts
    }
    _accountsCharts

    @Input()
    isReadOnly

    @Input()
    loaded = false
    @Input()
    set expense(val) {
        if (val) {
            this._expense = val
            if (this.expenseForm.value._id) {
                return
            }
            if (!val?.amounts?.length) {
                val.amounts = [{ value: 0, resource: '', resourceId: '' }]
            }
            console.log(val)
            this.expenseForm.patchValue(val)
        }
    }
    _expense
    @Input()
    fromStore: typeof fromCoreStore | typeof fromModalStore = fromCoreStore
    @Input()
    set categories(val) {
        if (val) {
            this.categoriesArray = Object.keys(val).map(key => {
                return { key: key, name: val[key] }
            })
        }
    }
    expenseForm: UntypedFormGroup
    isSelected: Boolean = false
    categoriesArray

    FOLLOW_UP_RESOURCE = FOLLOW_UP_RESOURCE
    multiSelectConfig: any = {
        ...MULTI_SELECT_PRESET_VALUE_CONFIG,
        "allowSearchFilter": false,
        "enableCheckAll": false
    }

    expDisabled: boolean
    statuses = [
        { name: 'NEW', key: 'NEW' },
        { name: 'IN REVIEW', key: 'IN_REVIEW' },
        { name: 'ACCEPTED', key: 'ACCEPTED' },
        { name: 'DENIED', key: 'DENIED' }
    ]
    tags = [{
        value: 'WARNING', label: 'WARNING'
    }, {
        value: 'REFUND', label: 'REFUND'
    },
    {
        value: 'MISSING_RECEIPTS', label: 'MISSING RECEIPTS'
    }
    ]
    resources = [{ name: FOLLOW_UP_RESOURCE.TRIP, key: FOLLOW_UP_RESOURCE.TRIP },
    { name: FOLLOW_UP_RESOURCE.ORDER, key: FOLLOW_UP_RESOURCE.ORDER },
    { name: FOLLOW_UP_RESOURCE.CLAIM, key: FOLLOW_UP_RESOURCE.CLAIM },
        // { name: FOLLOW_UP_RESOURCE.SHOP_SERVICE, key: FOLLOW_UP_RESOURCE.SHOP_SERVICE }
    ]

    paymentTypes = [
        {key: 'ach', name:'ACH / Zelle / Venmo'},
        {key: 'companyCard', name:'Company Card'},
        {key: 'cash', name:'COP/COD Cash'},
        {key: 'reimburse', name:'Driver Reimburse'},
        {key: 'check', name:'Check'}
    ]
    destroyed$ = new Subject<boolean>()
    selectedTab = 'notes'

    constructor(
        private store: Store<fromCoreStore.State>,
        private modalStore: Store<fromModalStore.State>,
        private fb: UntypedFormBuilder,
        private notify: ToastHelper,
        private popupService: popupService,
        private _Router: Router,
        private cdr: ChangeDetectorRef,
        private expenseService: ExpenseService
    ) {
        this.expenseForm = this.fb.group({
            _id: [''],
            status: ['', Validators.required],
            expenseDate: [''],
            checkNo: [''],
            merchant: [''],
            category: ['', Validators.required],
            amounts: [[], Validators.required],
            reimbursement: [false],
            closed: [false],
            txnDate: [''],
            paymentType: [''],
            desc: [null],
            employeeId: [''],
            employeeName: [''],
            vendorId: [''],
            vendorName: [''],
            accountChart: [null],
            tags: [[]]
        })

    }
    ngOnInit() {
        this.expenseForm.get('closed').valueChanges
            .subscribe(value => {
                const txnDateControl = this.expenseForm.get('txnDate')
                if (value) {
                    txnDateControl.setValidators(Validators.required)
                } else {
                    txnDateControl.clearValidators()
                }
                txnDateControl.updateValueAndValidity()
            })

            this.expenseService.patchLocal$.pipe(takeUntil(this.destroyed$)).subscribe(data=>{
                if (data?.contact) {
                    if (data?.type == 'vendor') {
                        this.expenseForm.get('vendorId').setValue(data?.contact?._id)
                        this.expenseForm.get('vendorName').setValue(data?.contact?.name)
                        this.cdr.detectChanges()
                        this.expenseService.clearPatchLocal()
                    }
                }
            })
    }
    addAmount() {
        let array = this.expenseForm.get('amounts').value
        array.push({ value: 0, resource: '', resourceId: '' })
        this.expenseForm.get('amounts').setValue(array)
    }
    updateAmount(index, changes) {
        let array = this.expenseForm.get('amounts').value
        array[index] = { ...array[index], ...changes }
        this.expenseForm.get('amounts').setValue(array)
    }
    removeAmount(index) {
        let array = this.expenseForm.get('amounts').value
        array.splice(index, 1)
        this.expenseForm.get('amounts').setValue(array)
    }
    checkValidate() {
        this.expenseForm.invalid
            ? this.expDisabled = true
            : this.expDisabled = false
    }

    setValue(value: any, key) {
        this.expenseForm.get(key).setValue(value)
    }

    setVendor($event) {
        this.expenseForm.get('vendorId').setValue($event._id)
        this.expenseForm.get('vendorName').setValue($event.name)
    }
    clearVendor() {
        this.expenseForm.get('vendorId').setValue('')
        this.expenseForm.get('vendorName').setValue('')
    }

    setEmployee($event) {
        this.expenseForm.get('employeeId').setValue($event._id)
        this.expenseForm.get('employeeName').setValue($event.name)

        if (this.accountsCharts.length) {
            this.accountsCharts.map(ac => {
                if (ac.contactId === $event._id) {
                    this.expenseForm.get('accountChart').setValue(ac._id)
                }
            })
        }
    }
    clearEmployee() {
        this.expenseForm.get('employeeId').setValue('')
        this.expenseForm.get('employeeName').setValue('')
    }

    setAccountChart($event) {
        this.expenseForm.get('accountChart').setValue($event || null)
    }

    onDateChange($event, key) {
        const date = $event[key] ? moment($event[key].substring(0, $event[key].length - 1)).format('YYYY-MM-DD') : ''
        this.expenseForm.get(key).setValue(date)
    }


    saveChange() {
        const data = {
            ...this.expenseForm.value
        }
        this.onChange.emit(data)
    }
    save() {
        if (this.expenseForm.invalid || this.isSelected) {
            const formValidStatus = markControlsAsTouched(this.expenseForm)
            if (!formValidStatus || this.isSelected) {
                this.notify.error('Please enter valid form inputs!')
                return
            }
        }
        const data = {
            ...this.expenseForm.value,
            employee: this.expenseForm?.value?.employeeId && this.expenseForm?.value?.employeeId !== ''
                ?
                {
                    employeeId: this.expenseForm?.value?.employeeId,
                    name: this.expenseForm?.value?.employeeName
                }
                :
                {
                    employeeId: '',
                    name: '',
                },
            vendor: this.expenseForm?.value?.vendorId && this.expenseForm?.value?.vendorId !== ''
                ?
                {
                    _id: this.expenseForm?.value?.vendorId,
                    name: this.expenseForm?.value?.vendorName
                }
                :
                {
                    _id: null,
                    name: null,
                },
        }
        delete data.employeeName
        delete data.employeeId
        delete data.vendorName
        delete data.vendorId
        this.onSave.emit(data)
    }
    restore() {
        this.store.dispatch(
            new fromCoreStore.RestoreExpense(this._expense)
        )
        this.store.dispatch(
            new CloseModal()
        )
    }

    toggleResources = (resource: string) => {
        this.expenseForm.get('resource').setValue(resource)
        this.expenseForm.get('resourceId').setValue('')
    }

    openResource(resource, resourceId) {
        if (resource === FOLLOW_UP_RESOURCE.TRIP) {
            const params = {
                report: 'byTrip',
                startDate: moment(new Date('08/01/17')).startOf('month').format('MM/DD/YY'),
                endDate: moment().startOf('month').format('MM/DD/YY'),
                status: 'ACTIVE',
                ordersType: 'ALL',
                tripsIds: resourceId
            }
            const url = this._Router.serializeUrl(this._Router.createUrlTree(['/reports'], { queryParams: { ...params } }))
            let baseUrl = window.location.href.replace(this._Router.url, '')
            window.open(baseUrl + url, '_blank')
        } else if (resource === FOLLOW_UP_RESOURCE.ORDER) {
            const url = this._Router.serializeUrl(this._Router.createUrlTree([`/loadboard/${resourceId}`]))
            let baseUrl = window.location.href.replace(this._Router.url, '')
            window.open(baseUrl + url, '_blank')
        } else if (resource === FOLLOW_UP_RESOURCE.CLAIM) {
            const url = this._Router.serializeUrl(this._Router.createUrlTree([`/claims/${resourceId}`]))
            let baseUrl = window.location.href.replace(this._Router.url, '')
            window.open(baseUrl + url, '_blank')
        } else {
            return
        }
    }

    openInReport(tripId) {
        const params = {
            report: 'byTrip',
            startDate: moment(new Date('08/01/17')).startOf('month').format('MM/DD/YY'),
            endDate: moment().startOf('month').format('MM/DD/YY'),
            status: 'ACTIVE',
            ordersType: 'ALL',
            tripsIds: tripId
        }
        const url = this._Router.serializeUrl(this._Router.createUrlTree(['/reports'], { queryParams: { ...params } }))
        let baseUrl = window.location.href.replace(this._Router.url, '')
        window.open(baseUrl + url, '_blank')
    }

    openInTrip(tripId) {
        const url = this._Router.serializeUrl(this._Router.createUrlTree([`/trips`, tripId]))
        let baseUrl = window.location.href.replace(this._Router.url, '')
        window.open(baseUrl + url, '_blank')
    }


    openContactModal(contactId) {
        this.store.dispatch(
            new OpenModal({
                type: 'EditContact',
                props: { contactId }
            })
        )
    }

    async setTrip(i, trip) {
        if (trip?.driverPaid || trip?.driverConfirmation?.date) {
            if (await this.popupService.confirm(`Trip invoice has been ${trip?.driverPaid ? 'Paid' : 'Approved by the Driver'} still want to link expense?`) === false) {
                return
            }
        }

        const changes = { resourceId: trip._id, resourceName: trip.tripName }
        this.updateAmount(i, changes)
        this.cdr.detectChanges()
    }
    setOrder(i, event) {
        const changes = { resourceId: event?.item?._id, resourceName: event?.item?.quoteNumber }
        this.updateAmount(i, changes)
    }
    setClaim(i, event) {
        const changes = { resourceId: event?.claimId, resourceName: event.claimName }
        this.updateAmount(i, changes)
    }
    setRepair(event) {
        this._expense._repair = {}
        this._expense._repair.repairNumber = event.repairName
        this.expenseForm.get('resourceId').setValue(event?.jobId)
    }

    getTotalAmount() {
        let total = 0
        this.expenseForm.get('amounts').value.map(amount => {
            total += +amount.value
        })
        console.log(total)
        return total
    }

    uploadPhotos() {
        this.store.dispatch(new fromCoreStore.GetFiles({ resource: 'EXPENSE', resourceId: this._expense?._id }))
    }

    hasAccessToTrip = trip => trip?.creator?.companyId === AuthService.CURRENT_USER?.company?._id && (!this.currentUser?.permissions?.contact_roles_driver || trip?.driverIds?.includes(this.currentUser?._id))

    getStatusName(key) {
        return this.statuses.find(s => s.key === key)?.name
    }

    createOrder() {
        this.store.dispatch(new fromShopStore.CreateShopOrderFromExpense({ expenseId: this._expense._id }))
    }

    ngOnDestroy() {
        this.destroyed$.next(true)
        this.destroyed$.complete()
    }
}
