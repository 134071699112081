import { Contact } from '@app/shared';
import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {

  private patchSubject = new BehaviorSubject<{
    type?: string,
    contact?: Contact
  }>(null)

  patchLocal$ = this.patchSubject.asObservable()

  constructor() { }

  setPatchLocal(value: {
    type?: string,
    contact?: Contact
  }) {
    this.patchSubject.next(value)
  }
  clearPatchLocal() {
    this.patchSubject.next(null)
  }
}
